<template>
    <div class="section" key="consent">
        <div class="section-title">
            <div class="author"><img src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 3</span> Stimmt der andere Ehepartner der Scheidung zu?</h3>
            <p>Bitte geben Sie an, ob Ihr Ehepartner dem Scheidungsantrag voraussichtlich zustimmen wird.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.zustimmung" name="zustimmung" value="ja" id="radio-4-1">
            <label for="radio-4-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-checked-100.svg"></span><span>Ja, stimmt zu</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.zustimmung" name="zustimmung" value="nein" id="radio-4-2">
            <label for="radio-4-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-cancel-100.svg"></span><span>Nein, stimmt nicht zu</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.zustimmung" name="zustimmung" value="ungewiss" id="radio-4-3">
            <label for="radio-4-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-puzzled-100.svg"></span><span>Ungewiss / nicht sicher</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Consent',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>